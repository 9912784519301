module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"id":"fi.kouluruoka","name":"kouluruoka.fi","short_name":"Kouluruoka","description":"Koulujen lounaslistat nopeasti ja helposti","start_url":"/","background_color":"#10984B","theme_color":"#10984B","lang":"fi","display":"standalone","display_override":["window-controls-overlay","standalone","browser"],"icon":"src/images/logo_app.svg","icons":[{"src":"/static/images/logo_maskable.png","sizes":"512x512","type":"image/png","purpose":"any"}],"iarc_rating_id":"40ba63d9-6fed-4407-8537-371d6b253401","related_applications":[{"platform":"play","url":"https://play.google.com/store/apps/details?id=fi.kouluruoka","id":"fi.kouluruoka"}],"categories":["food"],"orientation":"any","screenshots":[{"src":"/screenshots/1.png","sizes":"1440x2879","type":"image/png"},{"src":"/screenshots/2.png","sizes":"1440x2879","type":"image/png"},{"src":"/screenshots/3.png","sizes":"1440x2879","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"62bbd38af4b85168f0c9699f221887dc"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-DVJH5C1K2P"],"gtagConfig":{"anonymize_ip":false,"cookie_expires":0}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"runtimeCaching":[{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":1}}]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
