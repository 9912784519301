exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dmenu-restaurant-id-js": () => import("./../../../src/pages/dmenu/[restaurantId].js" /* webpackChunkName: "component---src-pages-dmenu-restaurant-id-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-mestari-js": () => import("./../../../src/pages/mestari.js" /* webpackChunkName: "component---src-pages-mestari-js" */),
  "component---src-pages-palaute-js": () => import("./../../../src/pages/palaute.js" /* webpackChunkName: "component---src-pages-palaute-js" */),
  "component---src-pages-pelit-index-tsx": () => import("./../../../src/pages/pelit/index.tsx" /* webpackChunkName: "component---src-pages-pelit-index-tsx" */),
  "component---src-pages-pelit-sanapeli-app-tsx": () => import("./../../../src/pages/pelit/sanapeli/App.tsx" /* webpackChunkName: "component---src-pages-pelit-sanapeli-app-tsx" */),
  "component---src-pages-pelit-sanapeli-components-board-tsx": () => import("./../../../src/pages/pelit/sanapeli/components/Board.tsx" /* webpackChunkName: "component---src-pages-pelit-sanapeli-components-board-tsx" */),
  "component---src-pages-pelit-sanapeli-components-game-state-tsx": () => import("./../../../src/pages/pelit/sanapeli/components/GameState.tsx" /* webpackChunkName: "component---src-pages-pelit-sanapeli-components-game-state-tsx" */),
  "component---src-pages-pelit-sanapeli-components-key-tsx": () => import("./../../../src/pages/pelit/sanapeli/components/Key.tsx" /* webpackChunkName: "component---src-pages-pelit-sanapeli-components-key-tsx" */),
  "component---src-pages-pelit-sanapeli-components-keyboard-tsx": () => import("./../../../src/pages/pelit/sanapeli/components/Keyboard.tsx" /* webpackChunkName: "component---src-pages-pelit-sanapeli-components-keyboard-tsx" */),
  "component---src-pages-pelit-sanapeli-components-letter-tsx": () => import("./../../../src/pages/pelit/sanapeli/components/Letter.tsx" /* webpackChunkName: "component---src-pages-pelit-sanapeli-components-letter-tsx" */),
  "component---src-pages-pelit-sanapeli-helpers-tsx": () => import("./../../../src/pages/pelit/sanapeli/helpers.tsx" /* webpackChunkName: "component---src-pages-pelit-sanapeli-helpers-tsx" */),
  "component---src-pages-pelit-sanapeli-index-tsx": () => import("./../../../src/pages/pelit/sanapeli/index.tsx" /* webpackChunkName: "component---src-pages-pelit-sanapeli-index-tsx" */),
  "component---src-pages-tulokset-js": () => import("./../../../src/pages/tulokset.js" /* webpackChunkName: "component---src-pages-tulokset-js" */),
  "component---src-templates-menu-js": () => import("./../../../src/templates/menu.js" /* webpackChunkName: "component---src-templates-menu-js" */)
}

